.success-display {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-content {
  background-color: white;
  width: 300px;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(119, 119, 119, 0.04);

  img {
    width: 40%;
    display: block;
    margin: 20px auto;
  }

  h5 {
    text-align: center;
    margin: 20px 0px;
    color: var(--primary-color);
    font-weight: 600;
    padding: 15px;
  }

  button {
    color: var(--primary-color);
    padding: 12px 50px;
    display: block;
    margin: 50px auto;
    border: 1px solid var(--primary-color);
    border-radius: 6px;
  }
}

.message-contentx {
  width: 100%;
  background: rgba(229, 229, 229, 0.4);
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;

  .message-container {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(119, 119, 119, 0.04);
    border-radius: 8px;
    width: 287px;

    p {
      font-size: 14px;
      margin: 5px 0px 0px;
    }

    img {
      width: 40%;
      display: block;
      margin: 20px auto;
    }

    button {
      color: var(--primary-color);
      padding: 12px 40px;
      display: block;
      margin: 0px auto 30px;
      border: 1px solid var(--primary-color);
      border-radius: 6px;
    }
  }
}

.message-for-user {
  padding: 20px 30px;
}

.message-contenty {
  position: relative;
  background-color: white;
  width: 300px;
  height: auto;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(119, 119, 119, 0.04);
  text-align: center;
  padding: 20px;

  img {
    width: 20%;
    display: block;
    margin: 20px auto 10px;
  }

  h2 {
    text-align: center;
    margin: 0px 0px;
    // color: var(--primary-color);
    font-weight: 600 !important;
    font-size: 19px;
    padding: 2px 15px;
  }

  p {
    margin-top: -10px;
  }

  button {
    color: var(--primary-color);
    padding: 12px 50px;
    display: block;
    margin: 50px auto;
    border: 1px solid var(--primary-color);
    border-radius: 6px;
  }

  .table-holder {
    height: 280px;
    overflow: scroll;
    margin: 5px auto;
  }

  table {
    margin: 20px auto;
    width: 100%;

    padding: 10px;

    tr {
      td,
      th {
        padding: 12px;
        font-size: 14px;
      }
    }
    // background-color: red;
  }
}

.cancel-cons-err {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  * {
    font-size: 50px;
  }
}
