.change-pin-container {
  margin: 50px auto;
  // height: 50vh;
  width: 540px;
  //   height: 369px;
  padding: 40px;

  border: 1px solid #e0e7ed;
  border-radius: 8px;

  .form_input_wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 160%;
      color: #525c76;
      margin-bottom: 6px;

      span {
        color: red;
      }
    }

    input, select {
      left: 0px;
      top: 25px;
      border: 1px solid var(--primary-color);
      border-radius: 6px;
      padding: 10px;
    }

    .notice {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #25233a;
      margin-top: 10px;
    }
  }

  .login-btn {
    width: 100%;
    background: var(--primary-brand-color);
    border-radius: 4px;
    color: #ffffff !important;
    text-align: center;
    padding: 12px 0;
  }

  .stepper-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .go_back {
      display: flex;
      align-items: center;
      gap: 10px;

      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #25233a;
      cursor: pointer;
    }

    .steps {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #25233a;
    }
  }
}
