:root {
  --primary-brand-color: #fe7005;
  --secondary-brand-color: linear-gradient(
    270deg,
    #032870 25.78%,
    rgba(3, 40, 112, 0.6) 110%
  );
  --text-color: #25233a;
  --white: #fff;
  --global-bg: #ecf0f7;
  --blue-fade: #f9f9fc;
  --nav-color: #032870;
  --text-color: #57584e;
  --hover-blue: #197cd7;
  --pressed-blue: #2147a8;
  --disabled-blue: rgba(25, 124, 215, 0.7);
  --deeper-blue: #171832;
  --sky-blue: #26abc7;
  --white: #fff;
  --light-font: #8a92a6;
  --secondary-grey: #f7f7f9;
  --secondary-grey-text: #6f727a;
  --danger: #f32d2d;
  --success: #55bd68;
  --toastify-color-error: blue;
  --carton: #f7f7f9;
  --ash: #6c6e7f;
  --light-purple: #f7f6fd;
  --lighter-purple: rgba(235, 233, 249, 0.6);
  --primary-color-black: #232325;
}/*# sourceMappingURL=globalstyle.css.map */